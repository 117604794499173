import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';

import Header from '../molecules/Header';

import GlobalStyles from '../atoms/GlobalStyles';
import styled, {ThemeProvider} from 'styled-components';
import {theme} from '../../utils/styling';


const Holder = styled.main`
  display: flex;
  height: 100vh;
`;

class Layout extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <StaticQuery
          query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
          render={data => (
            <Holder>
              <GlobalStyles />
              <Header />
              {this.props.children}
            </Holder>
          )}
        />
      </ThemeProvider>
    )
  }
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
