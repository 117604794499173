import React, {Component} from 'react';
import styled from 'styled-components';
import {graphql, StaticQuery, useStaticQuery} from 'gatsby';
import Job from '../molecules/Job';
import ColumnHolder from "../atoms/ColumnHolder";

const Holder = styled.section`
  width: 100%;
  @media ( ${props => props.theme.breakpoints.md} ) {
    width: 80rem;
  }
`;

const Header = styled.div`
  display: none;

  @media (${props => props.theme.breakpoints.lg}) {
    width: 100%;

    position: sticky;
    top: 0;
    z-index: 10;
    background-color: ${props => props.theme.colours.black};

    padding: 0.75rem 0;
    border-bottom: 1px solid;

    align-items: start;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 2fr 3fr 3fr 1fr 1fr 2fr 1fr 2fr;
    grid-column-gap: 2rem;
  }

  p {
    font-style: italic;
    ${props => props.theme.typeStyles(-4)};
    margin: 0;
    @media (${props => props.theme.breakpoints.lg}) {
      display: block;
    }

    &:last-child {
      justify-self: end;
    }
  }
`;

function JobList() {
  const data = useStaticQuery(graphql`
            query JobListQuery {
            allMarkdownRemark(
                filter: {
                    fileAbsolutePath: {regex : "\/_posts/websites/"}
                }
                sort: {
                    fields: [frontmatter___date]
                    order: DESC
                }
            ){
                edges {
                    node{
                        id
                        html
                        frontmatter {
                            title
                            date
                            client
                            clientsClient
                            task
                            status
                            design
                            cost
                            thumbnail
                            thumbnail2
                            thumbnail3
                            url
                            tech
                            turnaround
                        }
                    }
                }
            }
          }`)

  return (
    <ColumnHolder width="120rem" title="Job list">
      <Header>
        <p>Title</p>
        <p>Client</p>
        <p>Client's Client</p>
        <p>Status</p>
        <p>Task</p>
        <p>Technology</p>
        <p>Year</p>
        <p>Cost</p>
        <p>Design</p>
        <p>Duration</p>
        <p>Visuals</p>
      </Header>

      {data.allMarkdownRemark.edges.map((edge, i) => (
        <Job key={edge.node.id} postNo={i} post={edge.node} />
      ))}

    </ColumnHolder>
  )
}

export default JobList;
