import React, {Component} from 'react';
import styled from 'styled-components';
import ColumnHolder from "../atoms/ColumnHolder";

const Holder = styled.div`
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  .hero {
    ${props => props.theme.typeStyles( 4 )};
  }
`;

function About() {
  return (

    <ColumnHolder title="About">
      <Holder>
        <p className="hero">We have been building well-designed, high-impact and difficult projects for budgets grounded
          in this universe since 2019.</p>
        <p><em>Will's Websites</em> is a design and development studio run
          by <a href="https://www.linkedin.com/in/william-mclean-aba47b110" target="_blank"
                rel="noopener noreferrer">Will
            McLean</a>. We are based in <a
            href="https://www.google.com/maps/place/Berwick,+Polegate+BN26+6SW/@50.8241381,0.1430418,15z/data=!3m1!4b1!4m5!3m4!1s0x47df7ab3e1451db1:0xa299235b4c11fec2!8m2!3d50.824139!4d0.151818"
            target="_blank" rel="noopener noreferrer"> Berwick, East Sussex, UK</a> but work for clients all over the world.
        </p>
        <p>We have delivered jobs for large corporates, independent artists and everything in between. We craft solutions to budgets of any size.</p>
        <p>All our projects are delivered in close collaboration with client and designer. If you need us to work
          within teams then we can, if you never want to lay eyes on us that is OK too. We handle changing briefs,
          expanding scope and moving deadlines with grace, good humour and transparency. We deliver fast. Our projects happen in weeks not years. If you
          want
          our advice it is there, if you don’t then we will let you run the show. We just get the project done without it feeling like the most difficult thing in the world.</p>
      </Holder>
    </ColumnHolder>
  )
}

export default About;
