import React from 'react';
import styled from 'styled-components';
import {graphql, useStaticQuery} from 'gatsby';
import Video from '../atoms/Video';
import ColumnHolder from "../atoms/ColumnHolder";

const Holder = styled.div`
  .embed-container {
    margin-bottom: 2rem;
  }
`;

function VideoGrid() {
  const data = useStaticQuery(graphql`
            query VideoGridQuery {
              full: allFile(
                filter: {sourceInstanceName: {eq: "videos"}},
                sort: {order: ASC, fields: name}
              ) {
                nodes {
                  id
                  publicURL
                }
              }
          }`);

  return (
    <ColumnHolder title="Videos">
      <Holder>
        {data.full.nodes.map(node =>
          <Video key={node.id} videoSrc={node.publicURL} />
        )}
      </Holder>
    </ColumnHolder>
  )
}

export default VideoGrid;
