import React, {Component} from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import ColumnHolder from "../atoms/ColumnHolder";

class Services extends Component {
  render() {
    return (

      <ColumnHolder title="Services">
        <p>We have done lots of tricky things for our clients and specialise in solving difficult things quickly.</p>
        <p>We are a JAMstack studio who uses Gatsby, a React based Static Site Generator for most of our projects.
          Sometimes we just use plain React if it is more straightforward to do so for the project.</p>
        <p>We spend a lot of time with Greensick Animation Platform. We use this for the many scroll based animations
          that are required in the projects we tend to specialise in. Many of our projects rely solely on this type of
          animation for their story-telling. We have spent a lot of time with this library.</p>
        <p>We work heavily with headless CMSs. For straightforward content modelling we love the Contentful free plan
          and have also done lots of work with Prismic. We are happy to investigate any other headless CMSs for you if
          there are ones that are more suitable.</p>
        <p>For more complex models and large quantities of data we have experimented successfully with Airtable as a
          CMS. The spreadsheet view of Airtable
          can be a huge time saver on certain projects. We are well-versed in organising large rafts of information
          (artworks, articles, projects, exhibitions) into digestable, searchable, well-structured sites.</p>
        <p>We host your project for free on Netlify. If you want it hosted on your own Netlify account then we can do
          that as long as we have access. If you need delivery via github in order for you to host it somewhere else
          then we provide that.</p>
        <p>After a recent project we are pouring a lot of time and energy into the three.js library in order to get fun
          3D stuff on the web. Please ask if you have a 3D project you are wanting to talk about.</p>
        <p>We love to research and discover new things. Please ask us to try things. We know how to make new and
          uncomfortable things work within budgets. We then refine project upon project. So please do ask us if you
          have a wild idea. Maybe we know how to do it already.</p>
        <p>We are sorry but we never work with WordPress. We can help you transition to a more flexible solution if you
          would like.</p>
      </ColumnHolder>
    )
  }
}

export default Services;
