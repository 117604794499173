import React, { Component } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import animationData from '../../assets/animation.json';

const Holder = styled.div`
  width: 8rem;
`;

class Logo extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <Holder>
        <Lottie options={defaultOptions}/>
      </Holder>
    )
  }
}

export default Logo;
