import React, { Component } from 'react';
import Layout from '../components/organisms/Layout';
import SEO from '../components/molecules/SEO';
import About from "../components/molecules/About";
import Services from "../components/molecules/Services";
import JobList from "../components/organisms/JobList";
import Fees from "../components/molecules/Fees";
import VideoGrid from "../components/organisms/VideoGrid";

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Home" keywords={[ `websites`, `will mclean`, `design` ]}/>
        <About/>
        <Services/>
        <Fees/>
        <JobList/>
        <VideoGrid/>
      </Layout>
    )
  }
}

export default IndexPage;
