import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import classNames from 'classnames';

const Holder = styled.div`
  flex-shrink: 0;
  height: 100%;
  border-right: 1px solid;
  width: 90vw;
  position: relative;
  transition: width 0.25s ease-in-out;
  padding-right: 3rem;
  overflow: hidden;
  @media ( ${props => props.theme.breakpoints.md} ) {
    width: ${props => props.width};
  }
  &.closed {
    width: 0;
  }
  > button {
    position: absolute;
    top: 0;
    left: 100%;
    transform: rotate(90deg);
    transform-origin: top left;
    line-height: 3rem;
    padding: 0 1rem;
    width: 100vh;
    text-align: left;
    text-transform: uppercase;
    background-color: white;
    z-index: 20;
  }
`;

const Inner = styled.div`
  padding: 1rem;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: 90vw;
  @media ( ${props => props.theme.breakpoints.md} ) {
    width: calc(${props => props.width} - 3rem);
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

function ColumnHolder({children, width, title}) {
  const [ closed, setClosed ] = useState( false );

  const holderClass = classNames({ closed: closed });
  return (
    <Holder width={width} className={holderClass}>
      <Inner width={width} className="inner">
        {children}
      </Inner>
      <button onClick={() => setClosed(!closed)}>{title}</button>
    </Holder>
  )
}

ColumnHolder.propTypes = {
  width: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

ColumnHolder.defaultProps = {
  width: '40rem',
};

export default ColumnHolder;