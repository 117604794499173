import React from 'react';
import PropTypes from 'prop-types';
import EmbedContainer from './EmbedContainer';

function Video({videoSrc}) {
  return (
    <EmbedContainer>
      <video
        muted
        loop
        playsInline
        controls={true}>
        <source src={videoSrc} type="video/mp4" />
      </video>
    </EmbedContainer>
  )
}

Video.propTypes = {
  videoSrc: PropTypes.string.isRequired,
};

export default Video;
