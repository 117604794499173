import React, {Component} from 'react';
import styled from 'styled-components';
import Logo from '../atoms/Logo';

const Holder = styled.header`
  width: 20rem;
  flex-shrink: 0;
  height: 100%;
  border-right: 1px solid;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  background-color: darkred;
  color: white;
  h1 {
    margin: 0;
  }
  .phone {
    margin: 0;
    ${props => props.theme.typeStyles( -2 )};
  }
  .strap {
    ${props => props.theme.typeStyles( 2 )};
    font-style: italic;
    line-height: 1.4;
  }
  .quote {
    font-style: italic;
    font-weight: bold;
  }
`;

class Header extends Component {
  render() {
    return (
      <Holder>
        <Logo />
        <h1>Will's Websites</h1>
        <p className="phone"><a href="tel:+4407796360797">+44 (0) 77 9636 0797</a><br /></p>
        <p className="strap">Durable. <br/>Stylish. <br/>Affordable.</p>
        <p className="quote"><em>"World-class tech<br />for the people"</em></p>
      </Holder>
    )
  }
}

export default Header
