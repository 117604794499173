import React, {Component} from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import ColumnHolder from "../atoms/ColumnHolder";


class Fees extends Component {
  render() {
    return (

      <ColumnHolder title="Fees">
        <p><em>Will's Websites</em> is a design and web development studio run
          by <a href="https://www.linkedin.com/in/william-mclean-aba47b110" target="_blank"
                rel="noopener noreferrer">Will
            McLean</a>. We are based in <a
            href="https://www.google.com/maps/place/Berwick,+Polegate+BN26+6SW/@50.8241381,0.1430418,15z/data=!3m1!4b1!4m5!3m4!1s0x47df7ab3e1451db1:0xa299235b4c11fec2!8m2!3d50.824139!4d0.151818"
            target="_blank" rel="noopener noreferrer"> Berwick, East Sussex, UK</a>
        </p>
        <p>All our projects are delivered in close collaboration with client and designer. If you need us to work
          within teams then we can, if you never want to lay eyes on us that is OK too. We handle changing briefs,
          expanding scope and moving deadlines with grace, good humour and transparency. We deliver fast. If you
          want
          our advice it is there, if you don’t then we will let you run the show. We get it done.</p>
        <p>We research and discover new things. Please ask us to try things. We know how to make new and
          uncomfortable things work within budgets. We then refine project upon project. So please do ask us if you
          have a wild idea. Maybe we know how to do it already.</p>

        <p>We are currently putting a lot of energy into three js.
        </p>
      </ColumnHolder>
    )
  }
}

export default Fees;
